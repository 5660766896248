@import '../../../../scss/theme-bootstrap';

.elc-social-shoppe {
  background: $color--black;
  height: $social-shopping-bar-height;
  @include breakpoint($bp--largest-up) {
    height: $social-shopping-bar-height-large;
  }
  a {
    display: block;
    height: 100%;
    border: none;
    text-decoration: none;
    font-size: 14px;
    text-transform: none;
    font-family: $ano-bold_regular-font;
    .elc-social-shoppe__text {
      color: $color--white;
    }
  }
  &__wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 15px;
    margin: auto;
    max-width: $width-xxxlarge;
  }
  &__logo,
  &__logo img,
  &__text {
    display: block;
    width: auto;
    height: 100%;
  }
  &__logo {
    @include breakpoint($bp--largest-up) {
      margin-#{$ldirection}: auto;
    }
  }
  &__text {
    display: flex;
    font-size: 12px;
    align-items: center;
    position: absolute;
    #{$rdirection}: 5px;
    max-width: 40%;
    text-align: #{$rdirection};
    @include breakpoint($bp--largest-up) {
      position: static;
      font-size: 16px;
    }
    svg {
      display: inline-block;
      width: 1rem;
      height: 12px;
      fill: $color--white;
      margin-#{$ldirection}: 3px;
      margin-top: -2px;
    }
  }
}

.site-header-formatter {
  height: calc(#{$navbar-height} + #{$site-banner-mobile-height});
  position: relative;
  @include breakpoint($bp--largest-up) {
    min-height: $gnav-height;
  }
  .has-subnav & {
    @include breakpoint($bp--largest-up) {
      height: calc(#{$gnav-height} + #{$site-subnav-height});
    }
  }
  &__inner {
    @include transition(transform 0.25s ease);
    background-color: $color--black;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    .site-header-formatter--sticky & {
      z-index: calc(#{$gnav-fixed-z-index} + 1);
    }
    // Hide the sticky Nav when scrolling down.
    .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
    }
    .gnav-util-overlay-active & {
      @include breakpoint($bp--largest-up) {
        z-index: calc(#{$gnav-fixed-z-index} + 1);
      }
      &.site-header-formatter--sticky {
        @include breakpoint($bp--largest-up) {
          z-index: calc(#{$gnav-fixed-z-index} + 1);
        }
      }
    }
  }
  &__inner-content {
    @include breakpoint($bp--largest-up) {
      max-width: $gnav_max_width;
      margin: 0 auto;
      position: relative;
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    position: relative;
    height: 100%;
    opacity: 1;
    border-bottom: 1px solid $color--white;
    @include breakpoint($bp--largest-up) {
      height: $site-banner-height;
      border-bottom: none;
    }
    .header-offers-banner-hidden {
      opacity: 0;
    }
  }
  &__header {
    @include swap_direction(padding, 0 $container-pad-small 0 $container-pad-small);
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      padding: 0;
    }
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }
  &__bar {
    align-items: center;
    background-color: $color--black;
    display: flex;
    min-height: $navbar-height;
    position: relative;
    @include breakpoint($bp--largest-up) {
      position: inherit;
    }
  }
  &__logo {
    display: block;
    #{$ldirection}: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 33.33333%;
    height: $navbar-height;
    border: none;
    @include breakpoint($bp--largest-up) {
      position: relative;
      #{$ldirection}: 0;
      transform: unset;
      margin-#{$ldirection}: 14px;
      width: 119px;
    }
    svg {
      fill: $color--white;
      width: 100%;
      height: inherit;
    }
  }
  // Mobile menu trigger (Hamburger).
  &__menu-icon {
    cursor: pointer;
    clear: none;
    float: $rdirection;
    width: 22px;
    height: 22px;
    margin-#{$rdirection}: -100%;
    margin-#{$ldirection}: 0;
    border: none;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    &-label {
      width: 22px;
      height: 22px;
      display: block;
      svg {
        fill: $color--white;
        width: 100%;
        height: inherit;
      }
    }
  }
  &__utilities {
    display: flex;
    clear: none;
    height: 100%;
    #{$rdirection}: 0;
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
    position: absolute;
    background-color: $color--black;
    @include breakpoint($bp--largest-up) {
      height: $site-banner-height;
      top: $container-pad-small;
    }
    @include breakpoint($bp--largest-up) {
      padding-#{$rdirection}: 17px;
      top: 0;
      width: auto;
    }
  }
  &__utilities-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    > * {
      flex: auto;
    }
  }
  &__utilities--loyalty {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
      position: absolute;
      #{$ldirection}: 0;
      padding-#{$ldirection}: 14px;
      padding-#{$rdirection}: 5px;
      top: 0;
      color: $color--white;
      background-color: $color--black;
    }
    &-link {
      @include unset-underline-links();
      line-height: $site-banner-height;
      color: $color--gray--lighter;
      text-transform: none;
      text-decoration: underline;
      font-size: 12px;
      &:hover,
      &:focus {
        color: $color--gray--lighter;
        text-decoration: underline;
      }
    }
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-menu-header {
    cursor: pointer;
    display: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    span {
      padding-#{$ldirection}: 12px;
      display: inline-block;
      font-size: 12px;
    }
    &-label {
      display: flex;
      align-items: center;
      padding-#{$ldirection}: $gnav-mobile-hpad--outer;
    }
    .header-nav-section__trigger:checked ~ & {
      display: block;
      font-family: $ano-black_regular-font;
      height: $gnav-mobile-link-height;
      line-height: $gnav-mobile-link-height;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: $color--white;
      z-index: 1;
      @include breakpoint($bp--largest-up) {
        display: none;
      }
    }
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &__links {
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    &-icon {
      height: 18px;
      width: 18px;
      fill: $color--white;
    }
    &-icon--loyalty {
      width: 8px;
      margin-#{$rdirection}: $gnav-mobile-hpad--outer;
    }
    &--loyalty {
      background-color: $color--red--dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
        display: none;
      }
    }
    &-link {
      font-family: $ano-black_regular-font;
      font-size: 15px;
      display: flex;
      align-items: center;
      height: $gnav-mobile-link-height;
      line-height: $gnav-mobile-link-height;
      margin: 0 $gnav-mobile-hpad--outer;
      color: $color--white;
      text-decoration: none;
      border: none;
      box-shadow: 0 1px 0 0 $color--nero;
      @include breakpoint($bp--largest-up) {
        font-family: $helvetica-font;
        font-weight: bold;
        font-size: 14px;
      }
    }
    &-link-label {
      &,
      &:hover,
      &:active,
      &:focus,
      &:visited {
        color: $color--white;
        padding-#{$ldirection}: 10px;
        line-height: normal;
      }
    }
  }
  &__links-container {
    width: 100%;
    background-color: $color--black;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &__background {
    @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
    background-color: $color--white;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index - 1;
    @include breakpoint($bp--largest-up) {
      top: $gnav-height;
    }
  }
  &__sections {
    display: none;
    @include breakpoint($bp--largest-up) {
      @include swap_direction(padding, 0 20px 0 40px);
      #{$ldirection}: 0;
      margin-top: 0;
      display: flex;
      justify-content: center;
      position: relative;
      width: calc(100% - 133px);
      height: 100%;
    }
    @include breakpoint($bp--xxlarge-up) {
      // 1280px
      width: calc(100% - 290px);
    }
    .site-header-formatter__mobile-trigger:not(:checked) ~ & {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: flex;
        z-index: 1;
      }
    }
    .site-header-formatter__mobile-trigger:checked ~ & {
      background-color: $color--white;
      height: 100%;
      position: fixed;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      z-index: $gnav-fixed-z-index;
      @include breakpoint($bp--largest-up) {
        overflow-y: hidden;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 133px);
        background-color: unset;
        padding: 0;
      }
      @include breakpoint($bp--xxlarge-up) {
        // 1280px
        width: calc(100% - 290px);
      }
    }
    &-checkout-navigation {
      display: none;
    }
  }
  &__sections-main-links {
    margin-top: 58px;
    align-items: center;
    @include breakpoint($bp--medium-up) {
      min-height: $navbar-height;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    @include breakpoint($bp--largest-up) {
      margin-top: 0;
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    opacity: 1;
    position: relative;
    width: 10px;
    height: 10px;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    svg {
      width: 100%;
      height: inherit;
    }
  }
  &--close-icon {
    &,
    input[type='radio'] ~ & {
      position: fixed;
      top: 24px;
      width: 20px;
      height: 20px;
      #{$rdirection}: 20px;
      z-index: 2;
      display: block;
      @include breakpoint($bp--largest-up) {
        display: none;
      }
      &::before {
        display: none;
      }
      svg {
        width: 100%;
        height: inherit;
      }
    }
    input[type='radio']:checked ~ & {
      &::after {
        display: none;
      }
    }
  }
  &__email-signup {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
      order: 1;
    }
    &::after {
      @include breakpoint($bp--largest-up) {
        content: '|';
        color: $color--white;
      }
      @include breakpoint($bp--xxlarge-up) {
        // 1280px
        content: '';
      }
    }
    & > div {
      display: inline-block;
    }
  }
  &__search {
    @include breakpoint($bp--largest-up) {
      order: 2;
    }
    @include breakpoint($bp--xxlarge-up) {
      // 1280px
      position: absolute;
      #{$rdirection}: 0;
      bottom: -#{$navbar-height};
      height: $navbar-height;
      display: flex;
      align-items: center;
      margin-#{$rdirection}: 17px;
    }
  }
  &__locator {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
      order: 3;
      margin: 0 7px;
    }
    &-link {
      @include unset-underline-links();
      display: flex;
      align-items: center;
      @include breakpoint($bp--largest-up) {
        height: 100%;
      }
    }
    &-link-icon {
      height: 18px;
      width: 18px;
      fill: $color--white;
    }
  }
  &__account {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
      order: 4;
    }
  }
  &__cart {
    @include breakpoint($bp--largest-up) {
      order: 5;
    }
  }
  &__checkout-mobile-back {
    cursor: pointer;
    border: none;
    &-icon {
      width: 20px;
      height: 20px;
      fill: $color--white;
      transform: rotate(90deg);
      transform-origin: 0 15px;
      margin-#{$ldirection}: 15px;
    }
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &-search-icon-wrapper {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    padding: 0 $gnav-mobile-hpad--outer;
    display: block;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &-search-input-field {
    @include swap_direction(padding, 18px 15px 18px 30px);
    border: none;
    cursor: pointer;
    box-shadow: 0 1px 0 0 $color--gray--white;
    font-family: $ano-bold_regular-font;
    background-color: transparent;
    color: $color--gray--lightest;
    font-size: 18px;
    &::placeholder {
      font-size: 18px;
      color: $color--gray--lightest;
    }
    &:disabled {
      cursor: pointer;
    }
  }
  &-search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-58%);
    #{$ldirection}: 22px;
    height: 20px;
    width: 20px;
    fill: $color--gray--lightest;
  }
  &__content-block-sitewide-banner {
    max-width: 1440px;
    margin: 0 auto;
  }
}

// Grey overlay covers content when gnav is expanded.
// Shared with the utility block.
label.site-header-formatter-overlay {
  &,
  input[type='radio'] ~ & {
    background-color: $color--black;
    cursor: pointer;
    display: none;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: visibility 0.3s, opacity 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index - 2;
    &::after,
    &::before {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
  // Show the overlay when a Main Section is selected on PC.
  .header-nav-section__trigger:checked + .header-nav-section__section + &,
  .gnav-util-trigger:checked + & {
    height: 100%;
    opacity: 0.75;
    visibility: visible;
    top: calc(#{$navbar-height} + #{$site-banner-mobile-height});
    @include breakpoint($bp--largest-up) {
      display: block;
      top: $gnav-height;
    }
  }
  // Open dark overlay for mobile. The previous section works only for PC
  .gnav-util-trigger:checked + & {
    display: block;
    top: calc(#{$navbar-height} + #{$site-banner-mobile-height});
    padding: 0;
    @include breakpoint($bp--largest-up) {
      display: block;
      top: $gnav-height;
    }
  }
}
// Overrides for specific sections and states applied to html and body.
html.active-gnav {
  overflow: hidden; // No scrolling the body when fixed nav sections are active.
  overflow-y: hidden; // ios safari.
  @include breakpoint($bp--largest-up) {
    overflow: auto;
    overflow-y: auto;
  }
  .site-header-formatter--hidden .site-header-formatter--sticky .site-header-formatter__inner {
    @include transform(unset);
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    overflow: hidden; // No scrolling the body when fixed nav sections are active.
    overflow-y: hidden; // ios safari.
    width: 100%;
    position: relative;
  }
}

// Checkout styles
.checkout,
.viewcart {
  .site-header-formatter__menu-icon {
    display: none;
  }
  .site-header-formatter__checkout-mobile-back {
    display: flex;
    align-items: center;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
}

.checkout {
  &:not(.cr21-refresh) & {
    .site-header-formatter {
      height: $navbar-height;
    }
    .site-header-formatter__sections {
      justify-content: unset;
    }
    .site-header-formatter__top,
    .site-header-formatter__utilities-item,
    .site-header-formatter__sections-main-links {
      display: none;
    }
    .site-header-formatter__sections-checkout-navigation {
      @include breakpoint($bp--largest-up) {
        display: flex;
        align-items: center;
      }
    }
  }
}

.minimal-header {
  $minimal-header-padding: 15px;
  &--padding {
    padding: #{$minimal-header-padding};
  }
  &.site-header-formatter {
    &.hide-site-header-on-scroll {
      min-height: auto;
      height: #{$gnav-height};
    }
    .site-header-formatter {
      &__inner {
        .site-header-formatter {
          &__top {
            margin: 0;
          }
          &__utilities {
            margin-inline-end: 0;
            width: auto;
          }
        }
      }
    }
  }
}
